/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of 
BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are 
proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, 
and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this 
material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package bigtincan-teams-app
 * @copyright 2010-2022 BigTinCan Mobile Pty Ltd
 * @author Bhuvan Kakkar <bhuvan.kakkar@bigtincan.com>
 */

import React, { useState, useEffect } from "react";
import { SearchBox } from "@fluentui/react-search-preview";
import * as microsoftTeams from '@microsoft/teams-js';
import { hubService } from "../../hubService";
import {
    Button,
    Divider,
    MessageBarBody,
    MessageBarTitle,
    Link,
    Caption1,
    Card,
    CardHeader,
    CardPreview,
    MessageBar,
    Skeleton,
    SkeletonItem,
    tokens,
} from "@fluentui/react-components";
import {
    Open16Regular,
    Delete24Regular,
    Open24Filled,
    Add28Filled,
    Dismiss24Filled,
    Checkmark24Filled,
    Folder24Filled,
    Add24Filled
} from "@fluentui/react-icons";
import {
    DrawerBody,
    DrawerHeader,
    DrawerHeaderTitle,
    OverlayDrawer,
} from "@fluentui/react-drawer";
import { SearchFileResult } from "../../components/SearchFileResult";
import Loader from "../../components/Loader";
import Header from "../../components/Header";
import { CounterBadge } from "@fluentui/react-components";
import { checkUserAuth } from "../../auth";
import { authentication } from "@microsoft/teams-js";
import { Glyph } from "@bigtincan/react-design-system";

const moment = require("moment");

const MeetingHome: React.FC<{}> = ({ }) => {
    let searchTimeout: any;
    const [appId, setAppId] = useState("");
    const [frameContext, setFrameContext] = useState("");
    const [defaultRegion, setDefaultRegion] = useState("");
    const [appClientId, setAppClientId] = useState("");
    const [userAuthenticated, setUserAuthenticated] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [files, setFiles] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [searchOpen, setSearchOpen] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [filesLoading, setFilesLoading] = useState(false);
    const [addedFilesLoading, setAddedFilesLoading] = useState(false);
    const [displayAuthError, setDisplayAuthError] = useState(false);
    const [genieSearchOpen, setGenieSearchOpen] = useState(false);
    const [isGenieResultLoading, setIsGenieResultLoading] = useState(false);
    const [genieSearchKeyword, setGenieSearchKeyword] = useState('');
    const [genieSearchResult, setGenieSearchResult] = useState<string>('');
    const [genieSearchResultArray, setGenieSearchResultArray] = useState([]);
    const [displayedWords, setDisplayedWords] = useState<string[]>([]);
    const [docs, setDocs] = useState<any[]>([]);
    const [isGenieEnabled, setIsGenieEnabled] = useState(false);
    const [displayNoFilesError, setDisplayNoFilesError] = useState(false);

    useEffect(() => {
        microsoftTeams.app.initialize().then(async () => {

            let appClientId: string;
            let appId: string; // APP ID coming from Microsoft Admin portal, being used for stage view
            let defaultRegion;
            let isGenieEnabled: boolean;
            let context = await microsoftTeams.app.getContext();
            let tenantId = context.user?.tenant?.id as string;
            let azureAdObjectId = context.user?.id as string;
            let meetingId = context.meeting?.id as string;

            if (!meetingId) {
                setDisplayNoFilesError(true);
            }

            let frameContext = microsoftTeams.app.getFrameContext();

            if (frameContext) {
                setFrameContext(frameContext);
                setIsLoading(true);
                setAddedFilesLoading(true);
            }

            const tenantSettings = await hubService.getTenantSettings(tenantId) as any;

            if (tenantSettings) {
                if (tenantSettings["BTC_REGION"]) {
                    defaultRegion = tenantSettings["BTC_REGION"];
                    setDefaultRegion(defaultRegion)
                }
                if (tenantSettings["APP_CLIENT_ID"]) {
                    appClientId = tenantSettings["APP_CLIENT_ID"];
                    setAppClientId(appClientId)
                }
                if (tenantSettings["MICROSOFT_ADMIN_APP_ID"]) {
                    appId = tenantSettings["MICROSOFT_ADMIN_APP_ID"];
                    setAppId(appId);
                }
                if (tenantSettings["GENIE_AI_ENABLED"]) {
                    isGenieEnabled = tenantSettings["GENIE_AI_ENABLED"] == "1" ? true : false;
                    setIsGenieEnabled(isGenieEnabled);
                }
            }

            const userAuthenticated = await checkUserAuth(defaultRegion) as boolean;

            if (userAuthenticated == true) {

                let userInfo = JSON.parse(localStorage.getItem("userInfo") as string);

                if (userInfo) {
                    setFirstName(userInfo.firstName);
                    setLastName(userInfo.lastName);
                    setUserEmail(userInfo.userEmail);
                    setUserAuthenticated(true);
                    setIsLoading(false);
                }

                const filesAddedToMeeting = await hubService.getContentAddedToMeeting(meetingId, azureAdObjectId, tenantId) as any;

                setSelectedFiles(filesAddedToMeeting);
                setAddedFilesLoading(false);

                microsoftTeams.app.notifySuccess();

            } else {
                setUserAuthenticated(false);
                setIsLoading(false);
            }
        });
    }, []);

    const handleAddToMeetingClickButton = async (event: any, file: any) => {
        let context = await microsoftTeams.app.getContext();
        let tenantId = context.user?.tenant?.id as string;
        let azureAdObjectId = context.user?.id as string;
        let meetingId = context.meeting?.id as string;
        let filePermId = file.file_perm_id;
        let isAlreadySelected = selectedFiles ? selectedFiles.some((f: any) => f.id === file.id) : false;

        if (file && !isAlreadySelected) {
            const updatedFiles = [...selectedFiles, file] as any;
            const actionType = "add";

            hubService.updateContentInMeeting(tenantId, azureAdObjectId, meetingId, filePermId, actionType, defaultRegion,).then((response: any) => {
                console.log(`${file.id} has been added to this meeting`)
            }).catch((error: any) => {
                console.log(error);
            });

            setSelectedFiles(updatedFiles);
        }
    };

    const handleAddGenieDocToMeeting = async (event: any, file: any) => {
        let context = await microsoftTeams.app.getContext();
        let tenantId = context.user?.tenant?.id as string;
        let azureAdObjectId = context.user?.id as string;
        let meetingId = context.meeting?.id as string;
        let fileUrn = file.documentUrn;
        let fileUrnArray = fileUrn.split("PermFile:");
        let filePermId = fileUrnArray[1];
        let isAlreadySelected = selectedFiles ? selectedFiles.some((f: any) => f.id === file.id) : false;

        if (file && !isAlreadySelected) {
            const updatedFiles = [...selectedFiles, file] as any;
            const actionType = "add";

            hubService.updateContentInMeeting(tenantId, azureAdObjectId, meetingId, filePermId, actionType, defaultRegion).then((response: any) => {
                console.log(`${file.id} has been added to this meeting`)
            }).catch((error: any) => {
                console.log(error);
            });

            setSelectedFiles(updatedFiles);
        }
    }

    const handleSearchAction = (event: any) => {
        let searchText = event.target.value;
        if (searchText.length > 1) {
            setFilesLoading(true);

            if (searchTimeout) {
                clearTimeout(searchTimeout);
            }

            searchTimeout = setTimeout(async () => {
                try {
                    let context = await microsoftTeams.app.getContext();
                    let tenantId = context.user?.tenant?.id;
                    let azureAdObjectId = context.user?.id;

                    if (tenantId && azureAdObjectId) {
                        hubService.getFileSearchResults(searchText, tenantId, azureAdObjectId).then((data: any) => {
                            if (data && data.files && data.files?.files) {
                                setFiles(data.files.files);
                                setSearchKeyword(searchText);
                                setFilesLoading(false);
                            }
                        }).catch((error: any) => {
                            setFilesLoading(false);
                        })
                    } else {
                        setFilesLoading(false);
                    }
                } catch (error) {
                    setFilesLoading(false);
                }
            }, 800);
        } else {
            setFilesLoading(false);
        }
    }

    const handleGenieSearchTextChange = (event: any) => {
        let genieSearchText = event.target.value;
        setGenieSearchKeyword(genieSearchText)
    }

    const handleGenieSearchAction = async (event: any) => {
        setIsGenieResultLoading(true);
        setDocs([]);

        if (genieSearchKeyword.length > 1) {
            setIsGenieResultLoading(true);

            if (searchTimeout) {
                clearTimeout(searchTimeout);
            }

            try {
                let context = await microsoftTeams.app.getContext();
                let tenantId = context.user?.tenant?.id;
                let azureAdObjectId = context.user?.id;
                let message: string = "";

                if (tenantId && azureAdObjectId) {

                    const onStreamResponse = async (relevantDocs: any[], searchResponse: any) => {
                        if (searchResponse) {
                            let { content, type } = searchResponse;
                            message += `${content}`;
                            setGenieSearchResult(message);
                            setIsGenieResultLoading(false);
                        } else if (relevantDocs && relevantDocs.length > 0) {
                            setDocs(relevantDocs);
                        }
                    }

                    hubService.getGenieSearchResult(genieSearchKeyword, tenantId, azureAdObjectId, onStreamResponse).then(async (aiSearchData) => {
                        // do nothing as the messages are being sent progressively by onStreamResponse
                    });
                } else {
                    setIsGenieResultLoading(false);
                }
            } catch (error) {
                setIsGenieResultLoading(false);
            }
        } else {
            setIsGenieResultLoading(false);
        }
    };

    const handleAddContentIconClick = (event: any) => {

        setSearchOpen(!searchOpen);

        if (!searchOpen) {
            setFiles([]);
        }
    };

    const handleAskGenieClick = (event: any) => {

        setGenieSearchOpen(!genieSearchOpen);

        if (!genieSearchOpen) {
            setDocs([]);
            setGenieSearchResult("");
            setIsGenieResultLoading(false);
            setGenieSearchResultArray([]);
            setDisplayedWords([]);
        }
    };

    const handleSignOutClick = async () => {

        setUserAuthenticated(false);
        setIsLoading(true);

        let context = await microsoftTeams.app.getContext();
        let azureAdObjectId = context.user?.id as string;
        let tenantId = context.user?.tenant?.id as string;

        await hubService.signOutUser(tenantId, azureAdObjectId).then(() => {
            localStorage.clear();

            setUserAuthenticated(false);
            setIsLoading(true);
            setIsLoading(false);
            setFirstName("");
            setLastName("");
            setUserEmail("");
        });
    };

    const handleDeleteFileIconClick = async (event: any, file: any) => {
        let context = await microsoftTeams.app.getContext();
        let tenantId = context.user?.tenant?.id as string;
        let azureAdObjectId = context.user?.id as string;
        let meetingId = context.meeting?.id as string;
        let addedFiles = selectedFiles;
        let fileId = file.id;
        let filePermId = file.file_perm_id;

        const updatedSelectedFiles = addedFiles.filter((file: any) => file.id !== fileId);
        const actionType = "remove";

        hubService.updateContentInMeeting(tenantId, azureAdObjectId, meetingId, filePermId, actionType, defaultRegion).then((response: any) => {
            console.log(`${file.id} has been removed from this meeting`)
        }).catch((error: any) => {
            console.log(error);
        });

        setSelectedFiles(updatedSelectedFiles);
    }

    const handlePreviewFileClick = (event: any, file: any) => {
        let stageViewContext = encodeURIComponent(JSON.stringify({
            "contentUrl": `${window.location.origin}/app/fileViewer?permFileId=${file.file_perm_id}&fileId=${file.id}`,
            "websiteUrl": `${window.location.origin}/app/fileViewer?permFileId=${file.file_perm_id}&fileId=${file.id}`,
            "name": file.description || file.name
        }));

        microsoftTeams.app.openLink("https://teams.microsoft.com/l/stage/" + appId + `/0?context=${stageViewContext}`);
    }

    const handleOpenFileClick = (event: any, file: any) => {
        let frameContext = microsoftTeams.app.getFrameContext() as string;

        if (frameContext === "sidePanel") {
            window.open(file.fileLink);
        } else {
            window.open(`${window.location.origin}/app/fileViewer?permFileId=${file.file_perm_id}&fileId=${file.id}`);
        }
    }

    const hideAlert = () => {
        setTimeout(() => {
            setDisplayAuthError(false);
        }, 3000);
    }

    const signInWithBigtincan = async () => {
        setIsLoading(true);
        const queryString = window.location.search;
        const context = await microsoftTeams.app.getContext();
        const tenantId = context.user?.tenant?.id as string;
        const azureId = context.user?.id as string;
        const meetingId = context.meeting?.id as string;

        authentication.authenticate({
            url: `${window.location.origin}/app/auth-start-bigtincan?region=${defaultRegion}`,
            height: 800,
            width: 600,
        }).then(async (result: any) => {
            let data = JSON.parse(result);
            if (data) {
                let authData = data.authData;
                let userInfo = data.userInfo;
                localStorage.setItem("BTC", JSON.stringify(authData));
                if (userInfo) {

                    setFirstName(userInfo.firstName);
                    setLastName(userInfo.lastName);
                    setUserAuthenticated(true);

                    localStorage.setItem("userInfo", JSON.stringify(userInfo));

                    const filesAddedToMeeting = await hubService.getContentAddedToMeeting(meetingId, azureId, tenantId) as any;

                    setIsLoading(false);
                    setSelectedFiles(filesAddedToMeeting);
                    setAddedFilesLoading(false);

                    microsoftTeams.app.notifySuccess();

                } else {
                    setIsLoading(false);
                    setUserAuthenticated(false);
                }
            } else {
                setDisplayAuthError(true);
                setIsLoading(false);
                setUserAuthenticated(false);
            }
        }).catch((reason) => {
            setIsLoading(false);
            setDisplayAuthError(true), () => hideAlert();
        });
    }

    const contactUs = (event: any) => {
        event?.preventDefault();
        let userResponse = window.confirm("This link will take you to an external site. Do you want to proceed?");
        if (userResponse === true) {
            window.open("https://www.bigtincan.com/contact/");
        }
    }

    const getMeetingHomeView = () => {

        let frameContext = microsoftTeams.app.getFrameContext() as string;
        let addedFiles = selectedFiles
        let isFileSelected = false;

        if (isLoading) {
            return (
                <div style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "fixed"
                }}>
                    <Loader />
                </div>
            )
        }

        if (frameContext === "sidePanel") {
            return (
                userAuthenticated === true ?
                    <div style={{ height: "100vh", display: "flex", flexDirection: "column" }}>
                        <Header isSignedIn={userAuthenticated} onSignOutClick={handleSignOutClick} />
                        <div>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", height: "fit-content", width: "100%", alignItems: "center", padding: "10px" }}>
                                <div style={{ display: "flex ", flexDirection: "row", alignItems: "center" }}>
                                    <h3 style={{ flexWrap: "wrap", width: "130px" }}>Content added to the meeting</h3>
                                    {addedFiles.length > 0 && <CounterBadge count={addedFiles.length} appearance="filled" />}
                                </div >
                                <div style={{ display: "flex", gap: "10px", flexDirection: "row" }}>
                                    <Button onClick={handleAddContentIconClick} icon={<Add28Filled />}></Button>
                                    {isGenieEnabled &&
                                        <Button onClick={handleAskGenieClick} icon={< Glyph.Sparkles24 />}></Button>
                                    }
                                </div>
                            </div>
                            <Divider></Divider>
                            <div style={{
                                padding: "10px",
                            }}>
                                {addedFiles && addedFiles.length > 0 && !addedFilesLoading ?
                                    addedFiles.map((file: any, index: number) => {
                                        return (
                                            <Card key={index} style={{
                                                marginTop: "10px",
                                                maxWidth: "100%",
                                                height: "fit-content"
                                            }} orientation="horizontal">
                                                <CardPreview style={{
                                                    width: "64px",
                                                    height: "64px",
                                                }}>
                                                    <img
                                                        style={{
                                                            width: "64px",
                                                            height: "64px"
                                                        }}
                                                        src={file.cover_art?.url || file.thumbnailUrl}
                                                        alt={file.name || file.description}
                                                    />
                                                </CardPreview>
                                                <CardHeader
                                                    header={file.name || file.description}
                                                    style={{
                                                        width: "100%", flex: 1, flexWrap: "wrap", flexDirection: "column"
                                                    }}
                                                    action={
                                                        <>
                                                            <Button
                                                                appearance="transparent"
                                                                size="small"
                                                                icon={<Open24Filled />}
                                                                aria-label="open"
                                                                onClick={(event: any) => handleOpenFileClick(event, file)}
                                                            ></Button>
                                                            {/* <Button
                                                            appearance="transparent"
                                                            icon={<Delete24Regular />}
                                                            aria-label="More options"
                                                            onClick={(event: any) => handleDeleteFileIconClick(event, file)}
                                                        /> */}
                                                        </>
                                                    }
                                                />
                                            </Card>
                                        );
                                    })
                                    :
                                    <>
                                        {!addedFilesLoading && !displayNoFilesError &&
                                            < div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
                                                <h3>No content added</h3>
                                            </div>
                                        }
                                    </>
                                }
                                {displayNoFilesError &&
                                    <p> Unable to load added files, please close and open the Bigtincan tab again.</p>
                                }
                                {addedFilesLoading &&
                                    <div style={{
                                        margin: "0",
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)"
                                    }}>
                                        <Loader />
                                    </div>
                                }
                            </div>
                            <OverlayDrawer size={"full"} position="end" open={searchOpen}   >
                                <DrawerHeader  >
                                    <DrawerHeaderTitle
                                        action={
                                            <Button
                                                appearance="subtle"
                                                aria-label="Close"
                                                icon={<Dismiss24Filled />}
                                                onClick={handleAddContentIconClick}
                                            />
                                        }
                                    >
                                        Add Content
                                    </DrawerHeaderTitle>
                                </DrawerHeader>
                                <DrawerBody style={{ width: "100%" }}>
                                    <SearchBox appearance="underline" size="medium" placeholder="Search" style={{ width: "100%", position: "relative", marginBottom: "10px" }} onChange={handleSearchAction} />
                                    <div>
                                        {filesLoading &&
                                            <div style={{
                                                margin: "0",
                                                position: "absolute",
                                                top: "50%",
                                                left: "50%",
                                                transform: "translate(-50%, -50%)"
                                            }}>
                                                <Loader />
                                            </div>
                                        }
                                        {files && files.length > 0 && !filesLoading && !displayNoFilesError &&
                                            <>
                                                <p>{files.length} results found for <b>{searchKeyword}</b></p>
                                                <div style={{ height: "100%", display: "flex", flexWrap: "wrap", justifyContent: "space-between", overflow: "scroll" }}>
                                                    {files.map((file: any) => {
                                                        isFileSelected = selectedFiles ? selectedFiles.some((f: any) => f.id === file.id) : false;
                                                        return (
                                                            <div style={{ width: "100%" }} key={file.id}>
                                                                <SearchFileResult
                                                                    appId={appId}
                                                                    frameContext={frameContext}
                                                                    onClick={(event: any) => {
                                                                        handleAddToMeetingClickButton(event, file)
                                                                    }}
                                                                    selected={isFileSelected}
                                                                    file={file}
                                                                />
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </>
                                        }
                                        {files && files.length == 0 && !filesLoading &&
                                            <div style={{
                                                display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%"
                                            }}>
                                                <h3>No content available</h3>
                                            </div>
                                        }
                                    </div>
                                </DrawerBody>
                            </OverlayDrawer>
                            <OverlayDrawer size={"full"} position="end" open={genieSearchOpen}   >
                                <DrawerHeader  >
                                    <DrawerHeaderTitle
                                        action={
                                            <Button
                                                appearance="subtle"
                                                aria-label="Close"
                                                icon={<Dismiss24Filled />}
                                                onClick={handleAskGenieClick}
                                            />
                                        }
                                    >
                                        <div style={{ display: "flex" }}>
                                            <span style={{ paddingBottom: '10px' }}>Ask Genie</span>
                                            <span>{<Glyph.Sparkles24 />}</span>
                                        </div>
                                    </DrawerHeaderTitle>
                                </DrawerHeader>
                                <DrawerBody style={{ width: "100%", maxWidth: "100%" }}>
                                    <div style={{ width: "100%", display: "flex", flexDirection: "row", alignContent: "center", justifyContent: 'space-around' }}>
                                        <SearchBox appearance="underline" size="small" placeholder="Ask me anything..." onChange={(e) => handleGenieSearchTextChange(e)} style={{ width: "80%", position: "relative" }} />
                                        <Button style={{ width: "100%", marginLeft: "10px" }} appearance="primary" size="large" type="submit" icon={<Glyph.Sparkles24 />} onClick={handleGenieSearchAction}></Button>
                                    </div>
                                    <div>
                                        {filesLoading &&
                                            <div style={{
                                                margin: "0",
                                                position: "absolute",
                                                top: "50%",
                                                left: "50%",
                                                transform: "translate(-50%, -50%)"
                                            }}>
                                                <Loader />
                                            </div>
                                        }
                                        <div key={1} style={{
                                            marginTop: "10px",
                                            maxWidth: "90%",
                                            height: "700px",
                                            minHeight: "200px",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-start"
                                        }} >
                                            {isGenieResultLoading ?
                                                <Skeleton style={{ width: "100%" }}>
                                                    <div style={{ display: "flex", gap: "10px", flexDirection: "column" }}>
                                                        <SkeletonItem shape="rectangle" size={16} />
                                                        <SkeletonItem shape="rectangle" size={16} />
                                                        <SkeletonItem shape="rectangle" size={16} />
                                                        <SkeletonItem shape="rectangle" size={16} />
                                                        <SkeletonItem shape="rectangle" size={16} />
                                                        <SkeletonItem shape="rectangle" size={16} />
                                                        <SkeletonItem shape="rectangle" size={16} />
                                                        <SkeletonItem shape="rectangle" size={16} />
                                                        <SkeletonItem shape="rectangle" size={16} />
                                                        <SkeletonItem shape="rectangle" size={16} />
                                                        <br></br>
                                                        <SkeletonItem shape="rectangle" size={16} style={{ width: "60%" }} />
                                                        <br></br>
                                                        <div style={{ display: "flex", flexDirection: "row", gap: "10px", width: "70%", alignItems: "center" }}>
                                                            <SkeletonItem shape="circle" size={24} />
                                                            <SkeletonItem shape="rectangle" size={16} />
                                                        </div>
                                                        <div style={{ display: "flex", flexDirection: "row", gap: "10px", width: "70%", alignItems: "center" }}>
                                                            <SkeletonItem shape="circle" size={24} />
                                                            <SkeletonItem shape="rectangle" size={16} />
                                                        </div>
                                                        <div style={{ display: "flex", flexDirection: "row", gap: "10px", width: "70%", alignItems: "center" }}>
                                                            <SkeletonItem shape="circle" size={24} />
                                                            <SkeletonItem shape="rectangle" size={16} />
                                                        </div>
                                                        <div style={{ display: "flex", flexDirection: "row", gap: "10px", width: "70%", alignItems: "center" }}>
                                                            <SkeletonItem shape="circle" size={24} />
                                                            <SkeletonItem shape="rectangle" size={16} />
                                                        </div>
                                                    </div>
                                                </Skeleton>
                                                :
                                                <>
                                                    {genieSearchResult && genieSearchResult.length > 0 && !isGenieResultLoading && (
                                                        <p style={{ margin: 0, padding: 0, wordBreak: "break-word", whiteSpace: "pre-wrap" }}>
                                                            {genieSearchResult}
                                                        </p>
                                                    )}
                                                    {docs && docs.length > 0 &&
                                                        <>
                                                            <h3>Relevant documents ({docs.length})</h3>
                                                            {docs.map((file: any) => {
                                                                const isFileSelected = selectedFiles ? selectedFiles.some((f: any) => f.id == file.id) : false;
                                                                return (
                                                                    <div
                                                                        key={file.id}
                                                                    >
                                                                        <div style={{ display: "flex", alignItems: "flex-start" }}>
                                                                            <Folder24Filled style={{ marginRight: '10px', width: "20px", height: "20px", flexShrink: 0 }} />
                                                                            <p style={{ margin: 0, wordBreak: "break-word", width: "100%" }}>{file.name}</p>
                                                                            {isFileSelected ?
                                                                                <Checkmark24Filled onClick={(e) => { handleAddGenieDocToMeeting(e, file) }} /> :
                                                                                <Add24Filled onClick={(e) => { handleAddGenieDocToMeeting(e, file) }} />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                            <p style={{ position: "relative", padding: "10px" }}>*AI-generated content may be incorrect.</p>
                                                        </>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                </DrawerBody>
                            </OverlayDrawer>
                        </div>
                    </div >
                    :
                    <>
                        <div style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "fixed",
                            textAlign: "center",
                            flexDirection: "column"
                        }}>
                            <h3>Please sign in to get started</h3>
                            <Button appearance="primary" type="submit" onClick={() => signInWithBigtincan()} style={{ padding: "10px" }}>Sign in with Bigtincan</Button>
                            <p style={{ textAlign: "center", width: "80%" }}>You need an active Bigtincan account to use this app. Don't have a Bigtincan account ? <Link onClick={(e) => contactUs(e)} > Contact Us</Link></p>
                            {displayAuthError && (
                                <div style={{ padding: "10px" }}>
                                    <MessageBar intent="success">
                                        <MessageBarBody>
                                            <MessageBarTitle>Sign in failed, please try again</MessageBarTitle>
                                        </MessageBarBody>
                                    </MessageBar>
                                </div>
                            )}
                        </div>
                    </>
            );
        } else {
            return (
                userAuthenticated === true ?
                    <div style={{ height: "100vh", display: "flex", flexDirection: "column" }}>
                        <Header key={firstName} isSignedIn={userAuthenticated} onSignOutClick={handleSignOutClick} />
                        <div style={{ height: "fit-content", display: "flex", width: "100%", flexDirection: "column", padding: "5px" }}>
                            <div style={{ display: "flex", flexDirection: "row", height: "fit-content", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", flexGrow: 1 }}>
                                    <h3 style={{ marginLeft: "20px" }}>Content added to the meeting</h3>
                                    {addedFiles.length > 0 && <CounterBadge style={{ marginLeft: "10px" }} count={addedFiles.length} appearance="filled" />}
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                                    <Button style={{ height: "40px", marginRight: "20px" }} onClick={handleAddContentIconClick} appearance="primary" icon={<Add28Filled />}>Add</Button>
                                    {isGenieEnabled &&
                                        <Button style={{ height: "40px", marginRight: "20px" }} onClick={handleAskGenieClick} appearance="primary" icon={<Glyph.Sparkles24 />}> Genie</Button>
                                    }
                                </div>
                            </div>
                            <Divider></Divider>
                            <div style={{
                                padding: "10px",
                                width: "100%",
                            }}>
                                {addedFiles && addedFiles.length > 0 && !addedFilesLoading && !displayNoFilesError ?
                                    addedFiles.map((file: any, index: number) => {
                                        return (
                                            <Card key={index} style={{
                                                width: "100%",
                                                marginTop: "10px",
                                                maxWidth: "100%",
                                                height: "fit-content"
                                            }} orientation="horizontal">
                                                <CardPreview style={{
                                                    width: "80px",
                                                    height: "100%"
                                                }}>
                                                    <img
                                                        style={{
                                                            width: "80px",
                                                            height: "100%"
                                                        }}
                                                        src={file.cover_art?.url || file.thumbnailUrl}
                                                        alt={file.name || file.description}
                                                    />
                                                </CardPreview>
                                                <CardHeader
                                                    header={<div style={{ fontSize: "medium" }}>{file.description || file.name}</div>}
                                                    description={<div>
                                                        <Caption1 style={{ color: tokens.colorNeutralForeground3 }}>Date published: {(new Date(file.publishDate || file.created_at)).toLocaleDateString()}</Caption1><br></br>
                                                    </div>
                                                    }
                                                    style={{
                                                        width: "100%", flex: 1, flexWrap: "wrap", flexDirection: "row"
                                                    }}
                                                    action={
                                                        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                                                            <Button
                                                                style={{ width: "120px", marginRight: "10px" }}
                                                                appearance="primary"
                                                                aria-label="View"
                                                                icon={<Open16Regular />}
                                                                onClick={(event: any) => handlePreviewFileClick(event, file)}
                                                            >View</Button>
                                                            <Button
                                                                style={{ width: "120px" }}
                                                                appearance="transparent"
                                                                icon={<Delete24Regular />}
                                                                aria-label="Delete"
                                                                onClick={(event: any) => handleDeleteFileIconClick(event, file)}
                                                            ></Button>
                                                        </div>
                                                    }
                                                />
                                            </Card>
                                        );
                                    })
                                    :
                                    !addedFilesLoading && !displayNoFilesError &&
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
                                        <h3>No content added</h3>
                                    </div>
                                }
                                {displayNoFilesError &&
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
                                        <h3>Unable to load saved files, please close the Bigtincan tab and open again.</h3>
                                    </div>
                                }
                                {addedFilesLoading &&
                                    <div style={{
                                        margin: "0",
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)"
                                    }}>
                                        <Loader />
                                    </div>
                                }
                            </div>
                        </div>
                        <OverlayDrawer size={"medium"} position="end" open={genieSearchOpen}   >
                            <DrawerHeader  >
                                <DrawerHeaderTitle
                                    action={
                                        <Button
                                            appearance="subtle"
                                            aria-label="Close"
                                            icon={<Dismiss24Filled />}
                                            onClick={handleAskGenieClick}
                                        />
                                    }
                                >
                                    <div style={{ display: "flex" }}>
                                        <span style={{ paddingBottom: '10px' }}>Ask Genie</span>
                                        <span>{<Glyph.Sparkles24 />}</span>
                                    </div>
                                </DrawerHeaderTitle>
                            </DrawerHeader>
                            <DrawerBody style={{ width: "100%" }}>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <SearchBox appearance="underline" size="medium" placeholder="Ask me anything..." style={{ width: "100%", position: "relative" }} onChange={(e) => handleGenieSearchTextChange(e)} />
                                    <Button style={{ width: "100%", marginLeft: "10px" }} size="large" appearance="primary" type="submit" icon={<Glyph.Sparkles24 />} onClick={handleGenieSearchAction}></Button>
                                </div>
                                <div>
                                    {filesLoading &&
                                        <div style={{
                                            margin: "0",
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)"
                                        }}>
                                            <Loader />
                                        </div>
                                    }
                                    <div key={1} style={{
                                        marginTop: "10px",
                                        maxWidth: "100%",
                                        height: "fit-content",
                                        minHeight: "200px",
                                        padding: "10px",
                                        alignItems: "flex-start"
                                    }}>
                                        {isGenieResultLoading ?
                                            <Skeleton style={{ width: "100%" }}>
                                                <div style={{ display: "flex", gap: "10px", flexDirection: "column" }}>
                                                    <SkeletonItem shape="rectangle" size={16} />
                                                    <SkeletonItem shape="rectangle" size={16} />
                                                    <SkeletonItem shape="rectangle" size={16} />
                                                    <SkeletonItem shape="rectangle" size={16} />
                                                    <SkeletonItem shape="rectangle" size={16} />
                                                    <SkeletonItem shape="rectangle" size={16} />
                                                    <SkeletonItem shape="rectangle" size={16} />
                                                    <SkeletonItem shape="rectangle" size={16} />
                                                    <SkeletonItem shape="rectangle" size={16} />
                                                    <SkeletonItem shape="rectangle" size={16} />
                                                    <br></br>
                                                    <SkeletonItem shape="rectangle" size={16} style={{ width: "60%" }} />
                                                    <br></br>
                                                    <div style={{ display: "flex", flexDirection: "row", gap: "10px", width: "70%", alignItems: "center" }}>
                                                        <SkeletonItem shape="circle" size={24} />
                                                        <SkeletonItem shape="rectangle" size={16} />
                                                    </div>
                                                    <div style={{ display: "flex", flexDirection: "row", gap: "10px", width: "70%", alignItems: "center" }}>
                                                        <SkeletonItem shape="circle" size={24} />
                                                        <SkeletonItem shape="rectangle" size={16} />
                                                    </div>
                                                    <div style={{ display: "flex", flexDirection: "row", gap: "10px", width: "70%", alignItems: "center" }}>
                                                        <SkeletonItem shape="circle" size={24} />
                                                        <SkeletonItem shape="rectangle" size={16} />
                                                    </div>
                                                    <div style={{ display: "flex", flexDirection: "row", gap: "10px", width: "70%", alignItems: "center" }}>
                                                        <SkeletonItem shape="circle" size={24} />
                                                        <SkeletonItem shape="rectangle" size={16} />
                                                    </div>
                                                </div>
                                            </Skeleton>
                                            :
                                            <div>
                                                {genieSearchResult && genieSearchResult.length > 0 && !isGenieResultLoading && (
                                                    <p style={{ margin: 0, padding: 0, wordBreak: "break-word", whiteSpace: "pre-wrap" }}>
                                                        {genieSearchResult}
                                                    </p>
                                                )}
                                                {docs && docs.length > 0 &&
                                                    <>
                                                        <h3>Relevant documents ({docs.length})</h3>
                                                        {docs.map((file: any) => {
                                                            const isFileSelected = selectedFiles ? selectedFiles.some((f: any) => f.id == file.id) : false;
                                                            return (
                                                                <div
                                                                    key={file.id}
                                                                    style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%", padding: "10px" }}
                                                                >
                                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                                        <Folder24Filled style={{ marginRight: '10px' }} />
                                                                        <p style={{ margin: 0 }}>{file.name}</p>
                                                                    </div>
                                                                    {isFileSelected ?
                                                                        <Checkmark24Filled onClick={(e) => { handleAddGenieDocToMeeting(e, file) }} /> : <Add24Filled onClick={(e) => { handleAddGenieDocToMeeting(e, file) }} />
                                                                    }
                                                                </div>
                                                            );
                                                        })}
                                                        <p style={{ textAlign: "right" }}>*AI-generated content may be incorrect.</p>
                                                    </>
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            </DrawerBody>
                        </OverlayDrawer>
                        <OverlayDrawer size={"medium"} position="end" open={searchOpen}   >
                            <DrawerHeader  >
                                <DrawerHeaderTitle
                                    action={
                                        <Button
                                            appearance="subtle"
                                            aria-label="Close"
                                            icon={<Dismiss24Filled />}
                                            onClick={handleAddContentIconClick}
                                        />
                                    }
                                >
                                    Add Content
                                </DrawerHeaderTitle>
                            </DrawerHeader>
                            <DrawerBody style={{ width: "100%" }}>
                                <SearchBox appearance="underline" size="medium" placeholder="Search" style={{ width: "100%", position: "relative", marginBottom: "10px" }} onChange={handleSearchAction} />
                                <div>
                                    {filesLoading &&
                                        <div style={{
                                            margin: "0",
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)"
                                        }}>
                                            <Loader />
                                        </div>
                                    }
                                    {files && files.length > 0 && !filesLoading &&
                                        <>
                                            <p>{files.length} results found for <b>{searchKeyword}</b></p>
                                            <div style={{ height: "100%", display: "flex", flexWrap: "wrap", justifyContent: "space-between", overflow: "scroll" }}>
                                                {files.map((file: any) => {
                                                    isFileSelected = selectedFiles ? selectedFiles.some((f: any) => f.id === file.id) : false;
                                                    return (
                                                        <div style={{ width: "100%" }} key={file.id}>
                                                            <SearchFileResult
                                                                appId={appId}
                                                                frameContext={frameContext}
                                                                onClick={(event: any) => {
                                                                    handleAddToMeetingClickButton(event, file)
                                                                }}
                                                                selected={isFileSelected}
                                                                file={file}
                                                            />
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </>
                                    }
                                    {files && files.length == 0 && !filesLoading &&
                                        <div style={{
                                            display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%"
                                        }}>
                                            <h3>No content available</h3>
                                        </div>
                                    }
                                </div>
                            </DrawerBody>
                        </OverlayDrawer>
                    </div >
                    :
                    <>
                        <div style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "fixed",
                            textAlign: "center",
                            flexDirection: "column"
                        }}>
                            <h3>Please sign in to get started</h3>
                            <Button appearance="primary" onClick={() => signInWithBigtincan()} style={{ padding: "10px" }}>Sign in with Bigtincan</Button>
                            <p style={{ textAlign: "center", width: "80%" }}>You need an active Bigtincan account to use this app. Don't have a Bigtincan account ? <Link onClick={(e) => contactUs(e)} > Contact Us</Link></p>
                            {displayAuthError && (
                                <div style={{ padding: "10px" }}>
                                    <MessageBar intent="error">
                                        <MessageBarBody>
                                            <MessageBarTitle>Sign in failed, please try again</MessageBarTitle>
                                        </MessageBarBody>
                                    </MessageBar>
                                </div>
                            )}
                        </div>
                    </>
            );
        }
    }

    return getMeetingHomeView();
}

export default MeetingHome;